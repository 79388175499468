import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
// import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../../helpers/api_helper";
import { Loading } from "../../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";
import GridForm from "./grid";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { XTextArea } from "../../../components/common/inputs/XTextArea";

const NewProjectScore = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [grid, setGrid] = useState<any>([]);
  const [scores, setScores] = useState<any>({});
  const [students, setStudents] = useState<any>([]);
  const [selectedProjectScore, setSelectedProjectScore] = useState<any>([]);

  const params = new URLSearchParams(useLocation().search);

  const [initialValues, setInitialValues] = useState<any>({
    project_id: params.get("project_id"),
  });

  const [project, setProject] = useState<any>(null);

  const request = new ApiCore();

  useEffect(() => {
    if (params.get("project_id")) {
      request
        .get(Endpoints.projects + "/" + params.get("project_id"))
        .then((response: AxiosResponse) => {
          setProject(response.data);
          setInitialValues({
            project_id: params.get("project_id"),
            form: response.data.form,
            subject_id: response.data.subject_id,
            term: response.data.term,
            year: response.data.year,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const loadScores = (values: any, selectedTypes: any) => {
    console.log(values);
    setInitialValues(values);
    setSelectedProjectScore(selectedTypes);

    toast
      .promise(
        request.createFormData(Endpoints.projectScores + "/grid", values),
        {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";

              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }
      )
      .then((response: AxiosResponse) => {
        const data = response.data;
        setScores(response.data.scores);
        setStudents(response.data.students);
        updateGrid(data, selectedTypes, values);
        console.log(scores);
        console.log(students);
      });
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    setInitialValues(values);
    request
      .create(Endpoints.projectScores, values)
      .then((response: AxiosResponse) => {
        const data = response.data;
        console.log(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    console.log(values);
  };

  const updateGrid = (data: any, types: any, values: any) => {
    let score: any = {};
    let comment: any = {};
    console.log(data);
    console.log(types);
    console.log(values);

    data.students.forEach((element: any) => {
      if (score[`sid_${element.id.toString()}`]) {
      } else {
        score[`sid_${element.id.toString()}`] = "";
      }

      if (comment[`sid_${element.id.toString()}`]) {
      } else {
        comment[`sid_${element.id.toString()}`] = "";
      }

      if (data.scores[`${element.id.toString()}`]) {
        score[`sid_${element.id.toString()}`] =
          data.scores[`${element.id.toString()}`].score;
        comment[`sid_${element.id.toString()}`] =
          data.scores[`${element.id.toString()}`].comment;
      }
    });

    setInitialValues({ ...values, score: score, comment: comment });
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Project Scores - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            title="Project Scores"
            breadcrumbItem="Record project scores"
          />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Record project scores</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && (
                    <Loading label="Loading Project Scores. Please wait." />
                  )}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <GridForm
                            initialValues={initialValues}
                            handleSearch={(values: any, selectedTypes: any) => {
                              loadScores(values, selectedTypes);
                            }}
                            project={project}
                          />
                          <ToastContainer />
                        </Col>
                      </Row>
                      {students.length > 0 && (
                        <Formik
                          initialValues={initialValues}
                          enableReinitialize
                          validateOnBlur
                          onSubmit={(values: any) => handleSubmit(values)}
                        >
                          {({
                            isSubmitting,
                            setFieldValue,
                            values,
                            errors,
                            touched,
                          }) => (
                            <>
                              <Form>
                                {students.map((item: any, index: number) => {
                                  return (
                                    <>
                                      <Row>
                                        <Col>
                                          {item.lastname}&nbsp;
                                          {item.firstname}&nbsp;
                                          {item.othername}&nbsp;
                                          {item.studentid} - {item.form}{" "}
                                          {item.stream}
                                        </Col>
                                        <Col>
                                          <XTextBox
                                            type="text"
                                            label="Score"
                                            key={`score.sid_${item.id.toString()}`}
                                            id={`score.sid_${item.id.toString()}`}
                                            name={`score.sid_${item.id.toString()}`}
                                            touched={touched}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <XTextArea
                                          id={`comment.sid_${item.id.toString()}`}
                                          label="Comment"
                                          name={`comment.sid_${item.id.toString()}`}
                                          size={{ lg: 12 }}
                                          touched={touched}
                                        />
                                      </Row>
                                    </>
                                  );
                                })}

                                <div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    name="btn-submit"
                                    className="btn btn-primary"
                                  >
                                    Record Scores
                                  </Button>
                                </div>
                              </Form>
                              <hr />
                            </>
                          )}
                        </Formik>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewProjectScore;
