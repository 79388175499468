import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//  Inner Authentication
import LoginPage from "../pages/Authentication/LoginPage";
import CreateAccount from "../pages/Authentication/CreateAccount";
import Signout from "../pages/Authentication/Signout";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage";

//Error
import Error1 from "../pages/Error/Error1";
import Error2 from "../pages/Error/Error2";
import Error3 from "../pages/Error/Error3";
import ErrorBasic from "../pages/Error/ErrorBasic";
import ErrorCover from "../pages/Error/ErrorCover";

//utility
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageMaintenance from "../pages/Utility/PageMaintenance";

import CreateBusiness from "../pages/Authentication/CreateBusiness";
import SelectProducts from "../pages/Authentication/SelectProducts";
import Studentcategories from "../pages/Studentcategories";
import Subjects from "../pages/Subjects";

import StockLocations from "../pages/Locations";
import Tenants from "../pages/Tenants/index";

import Marktypes from "../pages/Marktypes";
import Students from "../pages/Students";
import StudentDetails from "../pages/Students/detail";
import Grades from "../pages/Grades";
import NewGrade from "../pages/Grades/create";
import Proportions from "../pages/Proportions";
import Publishmarks from "../pages/Publishmarks";
import Alevelscores from "../pages/Alevelscores";
import Remarktypes from "../pages/Remarktypes";
import Automarkcomments from "../pages/Automarkcomment";
import Autoreportcomments from "../pages/Autoreportcomment";
import CreateAttendance from "../pages/Attendance/create";
import CreateMarks from "../pages/Marks/create";
import DeleteMarks from "../pages/Marks/delete";
import Marksheet from "../pages/Marks/marksheet";
import AvgMarksheet from "../pages/Marks/avgmarksheet";
import SubjectComments from "../pages/Comments/subject";
import ReportComments from "../pages/Comments/report";
import DeleteComments from "../pages/Comments/delete";
import TerminalReport from "../pages/Reports/terminalreport";
import ProgressReport from "../pages/Reports/progressreport";
import CummulativeReport from "../pages/Reports/cummulativereport";
import Otherstats from "../pages/Reports/otherstats";
import Resultanalysis from "../pages/Reports/Resultanalysis";
import NewCompetency from "../pages/NewCurriculum/Competences/create";
import CompetencyDetails from "../pages/NewCurriculum/Competences/competency-details";
import CompetenciesList from "../pages/NewCurriculum/Competences";
import ProjectsList from "../pages/NewCurriculum/Projects";
import NewProject from "../pages/NewCurriculum/Projects/create";
import ProjectDetails from "../pages/NewCurriculum/Projects/project-details";
import NewProjectScore from "../pages/NewCurriculum/Projects/recordscores";
import MarksList from "../pages/Marks/marks-list";
import MarkTargets from "../pages/Marks/MarkTargets/marktargets";
import NC_SubjectComments from "../pages/NewCurriculum/Comments/subject-comments";
import GenericComments from "../pages/NewCurriculum/Comments/generic-comments";
import NC_ReportComments from "../pages/NewCurriculum/Comments/report-comments";
import NC_Marksheet from "../pages/NewCurriculum/Marks/marksheet";
import TerminalReports from "../pages/NewCurriculum/Reports/terminal-reports";
import CreateMarkTargets from "../pages/Marks/MarkTargets/create";

import Subjectteachers from "../pages/Subjectteachers";
import Classteachers from "../pages/Classteachers";
import Deputies from "../pages/Deputies";
import GradeFoot from "../pages/GradeFoot";
import ReportFooter from "../pages/ReportFooter";
import SubjectPapers from "../pages/SubjectPapers";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
  clazz?: string,
  ability?: string
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/dashboard", component: Dashboard },

  // Tenants
  { path: "/businesses", component: Tenants },

  // inventory
  { path: "/inventory/stock/locations", component: StockLocations },
  { path: "/subjects/list", component: Subjects },
  { path: "/subjects/teachers", component: Subjectteachers },
  { path: "/classteachers", component: Classteachers },
  { path: "/students/categories", component: Studentcategories },
  { path: "/students/list", component: Students },
  { path: "/students/:id", component: StudentDetails },
  { path: "/school/deputies", component: Deputies },
  { path: "/school/gradefoots", component: GradeFoot },
  { path: "/school/reportfooters", component: ReportFooter },
  { path: "/school/subjectpapers", component: SubjectPapers },

  // Marks
  { path: "/marks/categories", component: Marktypes },
  { path: "/marks/create", component: CreateMarks },
  { path: "/marks/delete", component: DeleteMarks, clazz: "School::Mark", ability: "manage" },
  { path: "/marks/marksheet", component: Marksheet },
  { path: "/marks/avgmarksheet", component: AvgMarksheet },
  { path: "/school/marks", component: MarksList },
  { path: "/school/marktargets", component: MarkTargets },
  { path: "/school/marktargets/create", component: CreateMarkTargets },

  // Attendance
  { path: "/attendance/create", component: CreateAttendance, clazz: "School::Remark", ability: "manage" },

  // Comments
  { path: "/comments/categories", component: Remarktypes },
  { path: "/comments/automarkcomments", component: Automarkcomments },
  { path: "/comments/autoreportcomments", component: Autoreportcomments },

  // Comments new cirriculum
  { path: "/school/mark_newcurriculumcomment", component: NC_SubjectComments },
  { path: "/school/mark_genericcomment", component: GenericComments },
  { path: "/school/ncreportremark", component: NC_ReportComments },

  // Grades
  { path: "/grades", component: Grades },
  { path: "/grades/new", component: NewGrade },
  { path: "/proportions", component: Proportions },
  { path: "/school/publishmarks", component: Publishmarks, clazz: "School::Publishmark", ability: "manage" },
  { path: "/alevelscores", component: Alevelscores },
  { path: "/comments/subject", component: SubjectComments },
  { path: "/comments/delete", component: DeleteComments, clazz: "School::Remark", ability: "manage" },

  { path: "/comments/report", component: ReportComments },
  { path: "/terminalreport", component: TerminalReport },
  { path: "/cummulativereport", component: CummulativeReport },
  { path: "/progressreport", component: ProgressReport },
  { path: "/otherstats", component: Otherstats },
  { path: "/resultanalysis", component: Resultanalysis },

  //Competences
  { path: "/school/topics", component: CompetenciesList },
  { path: "/competency-detail/:id", component: CompetencyDetails },
  { path: "/school/topics/new", component: NewCompetency },

  //Projects
  { path: "/school/projects", component: ProjectsList },
  { path: "/project-detail/:id", component: ProjectDetails },
  { path: "/school/projects/new", component: NewProject },
  { path: "/school/projectscores/new", component: NewProjectScore },

  // Marksheet new cirriculum
  { path: "/school/ncmarksheet", component: NC_Marksheet },

  // Reports new cirriculum
  { path: "/school/ncterminalreport", component: TerminalReports },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: LoginPage },
  { path: "/logout", component: Signout },
  { path: "/register", component: CreateAccount },
  { path: "/register/business", component: CreateBusiness },
  { path: "/register/business/products", component: SelectProducts },
  { path: "/auth/password/reset", component: ResetPasswordPage },

  { path: "/signup", component: CreateAccount },

  { path: "/error-404-basic", component: Error1 },
  { path: "/error-404-cover", component: Error2 },
  { path: "/error-403-basic", component: Error3 },
  { path: "/error-500-basic", component: ErrorBasic },
  { path: "/error-500-cover", component: ErrorCover },

  //utility page
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-maintenance", component: PageMaintenance },
];

export { userRoutes, authRoutes };
