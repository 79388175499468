import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { FieldArray, Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { Endpoints } from "../../utils/Endpoints";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosError, AxiosResponse } from "axios";
import { getForms } from "../../utils/Helpers";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../components/common/inputs/XFileInput";

const GridForm = (props: any) => {
  const [studentcategories, setStudentcategories] = useState<any>([]);
  const [marktypes, setMarktypes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMarktypes, setLoadingMarktypes] = useState<boolean>(false);

  const request = new ApiCore();

  const terms = [
    {value: 'I', text: 'I'},
    {value: 'II', text: 'II'},
    {value: 'III', text: 'III'}
  ]


  const handleSubmit = (values: any) => {
    let selectedMarkTypes = marktypes;
    
    if (values.marktypes && values.marktypes.length > 0) {
      selectedMarkTypes = [];
      marktypes.forEach((item: any) => {
        if (values.marktypes.includes(item.id)) {
          selectedMarkTypes.push(item);
        }
      })
    } 
    props.handleSearch(values, selectedMarkTypes);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {loading || loadingMarktypes && <Loading label="Loading records. Please wait." />}
          {!loading && !loadingMarktypes && (
            <>
              <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="form"
                            id="form"
                            name={"form"}
                            options={getForms()}
                            label={"Class"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="stream"
                            id="stream"
                            name={"stream"}
                            label={"Stream"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="term"
                            id="term"
                            name={"term"}
                            options={terms}
                            label={"Term"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="year"
                            id="year"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>                       
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Next
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GridForm;
